import React from 'react';

function Index() {
    return (
        <main className="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
            <div className="text-center">
                <h1 className="mt-4 text-3xl font-bold text-violet-600 tracking-tight text-gray-900 sm:text-5xl">
                    meachi.me
                </h1>
                <p className="mt-6 text-base leading-7 text-gray-600">
                    Coming soon
                </p>
            </div>
        </main>
    );
}

export default Index;
